@import "~base/product/detail";
@import "../components/productCommon";

.product-detail {
    &.container {
        position: relative;
        padding-block: 0 60px;
        margin-block: 0;
        background: var(--skin-background-color-2);
        min-height: 620px;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        &::after {
            content: "";
            width: 250%;
            height: 0;
            padding-top: 51%;
            border-radius: 100%;
            background: var(--skin-primary-color-invert-1);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__info {
        @include media-breakpoint-up(md) {
            padding: 0 0 24px 5%;
        }
    }

    &__info-controls {
        &:not(.card) {
            row-gap: 16px;
        }

        &.card {
            background-color: var(--skin-primary-color-invert-1);

            .card-body {
                row-gap: 16px;
                border-top: none;
                margin-inline: 5px;
                transition: all 0.5s ease-in-out, opacity 0s;
            }

            &:has(>.card-header) > .card-body {
                padding-top: 0;
            }

            .form-group {
                margin-bottom: 0;
                text-align: left;
            }

            .custom-control-label {
                font-family: var(--skin-medium-font);
                font-size: 1.125rem;
                color: var(--ac-neutrals-black);
                margin-left: 15px;
            }

            .purchase-label{
                font-family: var(--skin-medium-font);
                font-size: 1.125rem;
                color: var(--ac-neutrals-black);
            }

            &:has([name=purchaseType]:not(:checked)) .card-body {
                min-height: 0;
                height: 0;
                opacity: 0;
                overflow: hidden;
                padding-block: 0;
            }
        }
    }

    .custom-quantity-selector {
        @include media-breakpoint-up(sm) {
            justify-content: flex-end;
        }
    }

    .availability {
        margin-top: 0;
    }

    .attributes {
        padding: 0;
    }

    .product-breadcrumb {
        margin-bottom: 8px;
        @include media-breakpoint-up(md) {
            margin-bottom: 32px;
        }

        .breadcrumb {
            border-top: none;
        }
    }

    .product-name {
        border-bottom: none;

        @include media-breakpoint-down(sm) {
            margin-top: 12px;
        }
    }

    .primary-images {
        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
    }

    .description-and-detail {
        margin-top: 0.5em;

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }

        .description {
            margin-bottom: 0;

            .link-primary[data-toggle=collapse][aria-expanded=true] {
                display: none;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 16px;
            }
        }

        .details {
            margin-block: 1rem 0;

            @include media-breakpoint-up(md) {
                margin-block: 1rem;
            }
        }
    }

    .simple-quantity {
        margin-top: 0;
    }

    .prices {
        line-height: 1.1;
        padding: 0;

        .price .value {
            font-size: 1.25rem;
            font-weight: 400;
            color: var(--bs-gray-500);
            white-space: nowrap;

            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }
        }

        .product-total-price .price .value {
            color: var(--ac-neutrals-black);
            font-family: var(--skin-medium-font);
            font-size: 1.875rem;
            white-space: wrap;
            @include media-breakpoint-up(md) {
                font-size: 2.375rem;
            }
        }
    }

    .prices-add-to-cart-actions {
        padding: 0;

        .btn,
        .prices {
            display: block;
            width: 100%;
            margin-top: 8px;
        }

        @include media-breakpoint-down(xs) {
            position: static;
            background-color: transparent;
            box-shadow: none;
        }
    }

    .subscribe-pdp {
        width: 100%;
    }

    &__recommendations {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        .title {
            @include media-breakpoint-up(md) {
                margin-left: 45px;
            }
        }

        .recommendations__container {
            line-height: 1;

            .product {
                height: 100%;

                &-tile {
                    display: flex;
                    flex-direction: row;
                    min-height: 0;
                    height: 100%;
                    background-color: var(--skin-background-color-2);
                    border: none;
                    border-radius: 40px;
                    padding: 16px 16px 16px 20px;

                    .tile-body {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        text-align: left;
                        padding: 0 0 0 16px;
                        margin: auto;

                        .short-description {
                            font-size: 1em;
                            text-align: left;
                            padding-inline: 0;

                            @include media-breakpoint-down(md) {
                                font-size: 0.9375em;
                            }

                            @include media-breakpoint-down(sm) {
                                font-size: 0.8125em;
                            }
                        }
                    }

                    .link {
                        color: var(--skin-main-text-color-1);
                    }
                }
            }

            .image-container {
                flex: 40% 0 0;
                padding: 0;

                .tile-image {
                    aspect-ratio: 1 / 1;
                    object-fit: contain;
                    background: var(--skin-image-background-color-1);
                    padding: 10%;
                    clip-path: circle(50% at 50% 50%);
                }
            }
        }
    }

    .warning-message:not(.top-banner) {
        margin-bottom: 16px;

        @include media-breakpoint-down(xs) {
            margin-inline: -20px;
        }
    }
}
